@import url('https://fonts.cdnfonts.com/css/avenir');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add any additional custom styles here */
.header {
  @apply bg-green-dark text-white;
}

body {
  font-family: 'Raleway', sans-serif;
}

.threeD-effect {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.3);
  transform: translateZ(0) scale(1.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.threeD-effect:hover {
  transform: translateZ(0) scale(1.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4), 0 10px 10px rgba(0, 0, 0, 0.4);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bodoni Moda', sans-serif; /* Replace with the font you want for headers */
}

.service-card {
  @apply bg-beige-100 border border-beige-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300;
}

.service-card:hover {
  @apply bg-beige-100;
}

.footer {
  @apply bg-green-dark text-white;
}

body {
  @apply bg-beige-100; /* Use the beige color of your choice */
}

/* Custom font for Avenir */
.font-avenir {
  font-family: 'Avenir', sans-serif;
}


