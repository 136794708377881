.service-button {
  background-color: #758C6D; /* Updated green-dark color */
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s, color 0.3s;
}

.service-button:hover {
  background-color: #5c7156; /* Slightly darker green for hover */
}

.transition-all {
  transition: all 0.5s ease-in-out;
}

@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 1000px; /* Arbitrary large value to ensure it grows fully */
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    max-height: 1000px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

.slide-down {
  animation: slideDown 0.5s forwards;
}

.slide-up {
  animation: slideUp 0.5s forwards;
}

.bg-beige-100 {
  background-color: #EAD5C7;
}

.border-green-dark {
  border-color: #758C6D;
}
/* Ensure the scrollable container has a max height and auto scroll */
.scrollable-content {
  max-height: 80vh; /* Adjust this value as needed */
  overflow-y: auto;
  padding-right: 8px; /* Prevent content from being hidden behind the scrollbar */
}

/* Webkit-based browsers */
.scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: url('../logo.svg') no-repeat center;
  background-size: cover;
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #4CAF50; /* a green shade */
  border-radius: 10px;
}

/* Firefox scrollbar styling */
.scrollable-content {
  scrollbar-width: thin;
  scrollbar-color: #4CAF50 transparent;
}