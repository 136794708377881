/* BookButton.css */

.book-button {
    position: relative;
    overflow: hidden;
    font-size: 1.5rem; /* Increase font size */
    width: 150px; /* Increase width */
    height: 150px; /* Increase height */
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulsate 1.5s infinite;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .book-button.bg-green-dark:hover {
    background-color: #5c7156; /* Slightly darker green for hover */
  }
  
   
  .book-button.bg-white:hover {
    background-color: #f8f8f89a; /* Teal for hover when in contact section */
  }
  
  .bg-green-dark {
    background-color: #758C6D; /* Updated green-dark color */
  }
  
  .text-green-dark {
    color: #758C6D; /* Updated green-dark color */
  }
  
  @media (max-width: 768px) {
    .book-button {
      font-size: 0.875rem; /* Smaller font size */
      padding: 0.5rem 1.5rem; /* Smaller padding */
      width: auto;
      height: auto;
    }
  
    .fixed {
      padding-bottom: 1rem;
    }
  }